import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static targets = [
    "applicationCountry",
    "countryOfLoading",
    "shipmentType",
    "formContainer",
    "user",
    "productType",
    "packageType",
    "grossWeightContainer",
    "volumeContainer",
    "numberOfVehiclesContainer",
    "productTypeContainer",
    "packageTypeContainer"
  ];

  applicationCountrySelect = null;
  countryOfLoadingSelect = null;
  shipmentTypeSelect = null;
  userTypeSelect = null;
  userSelect = null;
  productTypeSelect = null;
  packageTypeSelect = null;

  connect() {
    this.initializeSelects();
    this.initializeFormVisibility();
  }

  initializeSelects() {
    this.applicationCountrySelect = new SlimSelect({ select: this.applicationCountryTarget });
    this.countryOfLoadingSelect = new SlimSelect({ select: this.countryOfLoadingTarget });
    this.shipmentTypeSelect = new SlimSelect({ select: this.shipmentTypeTarget });
    this.userSelect = new SlimSelect({ select: this.userTarget });
  }

  initializeFormVisibility() {
    if (this.hasShipmentTypeTarget && this.shipmentTypeTarget.value) {
      this.updateShipmentTypeForm();
    }
  }

  updateCountriesOfLoading() {
    const applicationCountryId = this.applicationCountryTarget.value;
    if (!applicationCountryId) return;

    fetch(`/invoice/entries/country_data/${applicationCountryId}`)
      .then((response) => response.json())
      .then((data) => {
        this.countryOfLoadingTarget.disabled = false;
        this.populateSelect(
          this.countryOfLoadingTarget,
          data.country_of_loading,
          "Select country of loading"
        );
      })
      .catch((error) => console.error("Error fetching country data: ", error));
  }

  updateUserList() {
    fetch("/invoice/entries/combined_users")
      .then((response) => response.json())
      .then((data) => {
        this.populateSelect(this.userTarget, data, "Select a user");
      });
  }

  updateShipmentTypes() {
    const countryId = this.applicationCountryTarget.value;
    if (!countryId) return;

    this.resetShipmentType();

    fetch(`/invoice/entries/shipment_types/${countryId}`)
      .then((response) => response.json())
      .then((data) => {
        this.shipmentTypeTarget.disabled = false;
        this.populateSelect(this.shipmentTypeTarget, data, "Select shipment type");
      })
      .catch((error) =>
        console.error("Error fetching shipment types: ", error),
      );
  }

  updateShipmentTypeForm() {
    const urlSegments = window.location.pathname.split('/');
    const applicationCountryName = this.applicationCountryTarget.selectedOptions[0].text;
    const shipmentType = this.shipmentTypeTarget.value;
    let invoiceEntryId = null;

    // Check if the URL has an invoice entry ID
    if (urlSegments.length >= 2) {
      // Get the second-to-last segment of the URL
      const potentialId = urlSegments[urlSegments.length - 2];
      // Check if it's a valid numeric ID
      if (!isNaN(potentialId)) {
        invoiceEntryId = potentialId;
      }
    }

    if (!applicationCountryName || !shipmentType) return;

    // Construct the URL based on whether there is an invoice entry ID
    let url = '/invoice/entries/shipment_type_form';
    if (shipmentType !== 'Select shipment type') {
      if (invoiceEntryId && applicationCountryName !== 'Sierra Leone') {
        url += `/${shipmentType}?existing_id=${invoiceEntryId}`;
      } else if (applicationCountryName === 'Sierra Leone' && shipmentType === 'RoRo') {
        url += `/sierra_${shipmentType}?`;
      }
      else {
        url += `/${shipmentType}`;
      }
    } else {
      return (
        document.getElementById("shipment-form-placeholder").innerHTML = ""
      )
    }

    fetch(url)
      .then(response => response.text())
      .then(html => {
        document.getElementById("shipment-form-placeholder").innerHTML = html;
        this.adjustFieldsVisibility(applicationCountryName);
        if (shipmentType === 'Bulk') {
          this.updateProductTypes(applicationCountryName);
        } else if (shipmentType === 'Genco') {
          this.updatePackageTypes(applicationCountryName);
        }
      })
      .catch(error => console.error("Error loading shipment form:", error));
  }

  adjustFieldsVisibility(country) {
    const countrySettings = {
      'Angola': {
        targets: ['grossWeightContainer', 'volumeContainer'],
        display: 'block'
      },
      'Cameroon': {
        targets: ['numberOfVehiclesContainer', 'productTypeContainer', 'packageTypeContainer'],
        display: 'block'
      }
    };

    const allTargets = ['grossWeightContainer', 'volumeContainer', 'numberOfVehiclesContainer', 'productTypeContainer', 'packageTypeContainer'];
    allTargets.forEach(target => {
      if (this[`has${target.charAt(0).toUpperCase() + target.slice(1)}Target`]) {
        this[`${target}Target`].style.display = 'none';
      }
    });

    if (countrySettings[country]) {
      countrySettings[country].targets.forEach(target => {
        if (this[`has${target.charAt(0).toUpperCase() + target.slice(1)}Target`]) {
          this[`${target}Target`].style.display = countrySettings[country].display;
        }
      });
    }
  }

  resetShipmentType() {
    if (this.hasShipmentTypeTarget) {
      this.shipmentTypeTarget.value = '';
      this.shipmentTypeSelect.setData([{ text: 'Select shipment type', value: '' }]);
    }

    document.getElementById("shipment-form-placeholder").innerHTML = '';
  }

  updateProductTypes(applicationCountryName) {
    if (!applicationCountryName) return;

    if (applicationCountryName === 'Cameroon') {
      fetch(`/invoice/entries/product_types/${applicationCountryName}`)
        .then((response) => response.json())
        .then((data) => {
          this.productTypeSelect = new SlimSelect({ select: this.productTypeTarget });
          this.productTypeSelect.setData(data.product_types.map((type) => ({ text: type, value: type })));
        })
        .catch((error) =>
          console.error("Error fetching product types: ", error),
        );
    } else {
      this.productTypeTarget.disabled = true;
    }
  }

  updatePackageTypes(applicationCountryName) {
    if (!applicationCountryName) return;

    if (applicationCountryName === 'Cameroon') {
      fetch(`/invoice/entries/package_types/${applicationCountryName}`)
        .then((response) => response.json())
        .then((data) => {
          this.packageTypeSelect = new SlimSelect({ select: this.packageTypeTarget });
          this.packageTypeSelect.setData(data.package_types.map((type) => ({ text: type, value: type })));
        })
        .catch((error) =>
          console.error("Error fetching package types: ", error),
        );
    }
  }

  populateSelect(selectElement, items, placeholder = "Select an option") {
    let options = [{ text: placeholder, value: '' }];

    // Handle array of arrays (country data format)
    if (Array.isArray(items) && items.length > 0 && Array.isArray(items[0])) {
      options = options.concat(
        items.map((item) => ({ text: item[0], value: item[1].toString() })),
      );
    }
    // Handle object with shipment_types array
    else if (items.shipment_types) {
      options = options.concat(
        items.shipment_types.map((type) => ({ text: type, value: type })),
      );
    }
    else if (items.product_types) {
      options = options.concat(
        items.product_types.map((type) => ({ text: type, value: type })),
      );
    }
    else if (items.package_types) {
      options = options.concat(
        items.package_types.map((type) => ({ text: type, value: type })),
      );
    }
    else {
      console.warn("Received data in an unexpected format:", items);
    }

    if (selectElement === this.countryOfLoadingTarget) {
      this.countryOfLoadingSelect = this.reinitializeSlimSelect(
        this.countryOfLoadingSelect,
        this.countryOfLoadingTarget,
        options,
      );
    } else if (selectElement === this.shipmentTypeTarget) {
      this.shipmentTypeSelect = this.reinitializeSlimSelect(
        this.shipmentTypeSelect,
        this.shipmentTypeTarget,
        options,
      );
    } else if (selectElement === this.userTarget) {
      this.userSelect = this.reinitializeSlimSelect(
        this.userSelect,
        this.userTarget,
        options,
      );
    } else {
      this.reinitializeSlimSelect(null, selectElement, options);
    }
  }

  reinitializeSlimSelect(slimSelectInstance, targetElement, options) {
    if (slimSelectInstance) {
      slimSelectInstance.destroy();
    }

    const newSlimSelectInstance = new SlimSelect({
      select: targetElement,
    });
    newSlimSelectInstance.setData(options);

    return newSlimSelectInstance;
  }
}
